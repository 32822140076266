<template>
<div class="basic_container">
  <el-col :span="20">
  <h3 style="height: 40px;margin: 0 auto;width: 120px;font-weight: bold">企业文化</h3>
  </el-col>
  <div>
  <div style=" display: flex;flex-direction: row;margin-top: 40px;width: 1300px;height: 400px">
    <el-image :size="50" :src="require('@/assets/wh.png')"></el-image>
    <div style="margin-left: 90px">
      <div style="text-align: left;" v-for="(item,i) in item_context" :key="i">
        <h4>{{item.text}}</h4>
        <p style="border-bottom: 1px solid gainsboro;padding-bottom: 10px;font-size: 12px">{{item.text_desc}}</p>
      </div>
    </div>
  </div>
  </div>

</div>
</template>
<script>
export default {
  name: "CorporateCulture",
  data(){
    return{
      item_context:[
          {
        text:"经营目标",
        text_desc:"立足高新技术，争创先进企业"
      },{
          text:"经营目标",
          text_desc:"立足高新技术，争创先进企业"
        },
        {
          text:"经营目标",
          text_desc:"立足高新技术，争创先进企业"
        },{
          text:"经营目标",
          text_desc:"立足高新技术，争创先进企业"
        }
      ]
    }
  }
}
</script>

<style scoped>
.basic_container {
  width: 1300px;
  position: relative;
  margin: 10px auto;
}
</style>
